<template>
  <el-dialog
    v-if="TextBookDialog.show"
    :title="TextBookDialog.title"
    :visible.sync="TextBookDialog.show"
    center
    width="50%"
    top="30vh"
  >
    <div class="main-wrap">
      <!-- <div class="title">{{TextBookDialog.title}}</div> -->
      <div class="cont">
        <img :src="TextBookDialog.imgUrl" />
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "ApplyRefund",
  props: {
    TextBookDialog: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      codeImgUrl:
        "https://www.wlmqcol.com/common/qrcode?text=weixin%3A//wxpay/bizpayurl%3Fpr%3DWUPdroszz",
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.main-wrap {
  .title {
    margin-bottom: 1.25rem;
  }
  .cont {
    text-align: center;
  }
}
</style>
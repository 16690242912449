<template>
  <div class="textbook">
    <Head title="我的教材"></Head>
    <div class="el">
      <el-table
          :data="tableData"
          class="tableClass"
          border
          style="width: 100%"
          :header-cell-style="{ background: '#f5f5f5' }"
          @click="handleItem"
      >
        <el-table-column type="index" width="50" label="序号" />
        <el-table-column prop="courseName" label="课程名称"> </el-table-column>
        <el-table-column label="资料名称">
          <template slot-scope="scope">
            <a v-if="scope.row.dataUrl" :href="scope.row.dataUrl" class="default" target="_blank">{{scope.row.dataName}}</a>
            <span v-else>{{scope.row.dataName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Operation" label="操作" width="110">
          <template slot-scope="scope">
            <el-button v-if="scope.row.dataName" type="primary" size="mini" @click="handleItem(scope.row)"
            >下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>
    <div class="pagination">
      <Pagination
          :total="search.total"
          :page.sync="search.page"
          :limit.sync="search.size"
          @pagination="getDataList"
      />
    </div>

    <!-- 显示教材弹窗 -->
    <text-book
        v-if="TextBookDialog.show"
        :text-book-dialog="TextBookDialog"
        @dialogClose="dialogClose"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import TextBook from "./components/TextBook";
import { getTextBook } from "@/api/myCenter";
import Head from './Head'
import axios from "axios";
export default {
  name: "Textbook",
  components: {
    Pagination,
    TextBook,
    Head
  },
  data() {
    return {
      TextBookDialog: {},
      search: {
        page: 1,
        size: 10,
        total: 0, //分页
      },
      total: 0, //分页
      tableData: [],
      /* {
        courseId: "6",
        courseName: "010101111111111",
        dataName: 'xdfsfdsf',
        dataUrl: 'sfsfsf'
      } */
      // ],
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      getTextBook(this.search).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.search.total = res.data.data.total;
          this.tableData = res.data.data.rows;
        }
      });
    },
    downloadFile(url) {
      axios({
        method: 'get',
        url: url,
        responseType: 'blob'
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'my-file.txt');
        document.body.appendChild(link);
        link.click();
      });
    },
    handleItem(row) {
      window.open(row.dataUrl,'_blank')
      // this.downloadFile(row.dataUrl, row.dataName + ".pdf")
      // debugger
      // const link = document.createElement('a');
      // link.href = row.dataUrl
      // link.download = row.dataName + ".pdf";
      // link.click();
      // window.open(row.dataUrl, '_blank')
      /* this.TextBookDialog.show = true;
      this.TextBookDialog = {
        show: true,
        title: "教材名称",
        imgUrl: row.dataUrl,
        orderId: this.orderId,
      }; */
    },
    // 弹窗关闭回调
    dialogClose() {},
  },
};
</script>

<style  rel="stylesheet/scss" lang="scss" scoped>
.textbook {
  background: #fff;
  padding-bottom: 20px;
  span {
    display: flex;
  }
  .tableClass {
    margin-top: 20px;
  }
  .pagination {
    margin: 20px 0;
  }
  img {
    border: none;
    padding: 3px;
    border: 2px #f5f5f5 solid;
    width: 150px;
    height: 50px;
    margin-top: 0;
  }
  .default{
    color:#409EFF;
  }
  .el{
    padding: 10px 20px;
    margin-top: 0px !important;
    // min-height: 62vh;
  }
}
</style>